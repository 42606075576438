a.nav-btn{
	align-items: center;
	background: $grad-neomorph-bg, #FF0000;
	border-radius: 50%;
	bottom: 20px;
	box-shadow: $shadow-neomorph;
	display: flex;
	flex: none;
	height: 50px;
	justify-content: center;
	// margin-top: 30px;
	position: absolute;
	transition: box-shadow .2s;
	width: 50px;

	.icon{
		fill: $black;
		height: 20px;
		width: 20px;

		&.inverted{
			fill: $white;
		}
	}

	&:active{
		box-shadow: $shadow-neomorph-inverse;
	}

	&.nav-btn-large{
		bottom:30px;
		height: 80px;
		width: 80px;

		.icon{
			height: 32px;
			width: 32px;
		}
	}

	&.nav-btn-home{
		background: linear-gradient(135deg, rgba(0, 0, 0, 0.02) 14.5%, rgba(255, 255, 255, 0.04) 84.5%), linear-gradient(180deg, #FBFBFB 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(133.97deg, #F5F5F5 19.65%, #FCFCFC 89.05%);
		box-shadow: -8px -8px 25px #FFFFFF, 10px 10px 19px rgba(0, 0, 0, 0.05);

		&:active{
			background: $grad-neomorph-bg-invers, $col-home;
			box-shadow: $shadow-neomorph-inverse;
		}
	}

	&.nav-btn-about{
		background: $grad-neomorph-bg, $col-about;

		&:active{
			background: $grad-neomorph-bg-invers, $col-about;
		}
	}

	&.nav-btn-services{
		background: $grad-neomorph-bg, $col-services;
		&:active{
			background: $grad-neomorph-bg-invers, $col-services;
		}
	}

	&.nav-btn-work{
		background: $grad-neomorph-bg, $col-work;
		&:active{
			background: $grad-neomorph-bg-invers, $col-work;
		}
	}

	&.nav-btn-contact{
		background: $grad-neomorph-bg, $col-contact;
		&:active{
			background: $grad-neomorph-bg-invers, $col-contact;
		}
	}
}

.btn{
		background: rgba(255,255,255,.2);
		border: 1px solid rgba(255,255,255,.3);
		border-radius: $border-radius;
		display: block;
		font-weight: 800;
		padding: 15px 40px;
		margin-top: 20px;
	}