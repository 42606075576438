$white: #FFF;
$black: #000;

$col-home: #F4F4F4;
$col-about: #FF6444;
$col-services: #FFC636;
$col-work: #00ADA9;
$col-contact: #474143;

$grad-neomorph-bg: linear-gradient(135deg, rgba(0, 0, 0, 0.02) 14.5%, rgba(255, 255, 255, 0.04) 84.5%);
$grad-neomorph-bg-invers: linear-gradient(135deg, rgba(255, 255, 255, 0.04) 84.5%, rgba(0, 0, 0, 0.02) 14.5%);

$shadow-neomorph: -8px -8px 25px rgba(255, 255, 255, 0.08), 10px 10px 15px rgba(0, 0, 0, 0.05);
$shadow-neomorph-inverse: 1px 1px 3px rgba(0, 0, 0, 0.2) inset;
$shadow-neomorph-large: 8px -8px 25px #FFFFFF, 10px 10px 25px rgba(0, 0, 0, 0.05);


// RETRO
// $white: #fcf8e8;
// $orange: #ecdfc8;
// $yellow: #ecb390;
// $turquoise: #df7861;

// PALE
// $white: #edf7fa;
// $orange: #5f6caf;
// $yellow: #ffb677;
// $turquoise: #ff8364;