@import url('https://fonts.googleapis.com/css?family=Barlow:300,800&display=swap');

@font-face {
  font-family: Barlow;
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Barlow-ExtraBold.ttf') format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Barlow-Light.ttf') format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
	font-family: 'Barlow', sans-serif;
  font-size: 16px;
	font-weight: 300;
	text-align: center;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Barlow', sans-serif;
	font-weight: 800;
}

h1{
  font-size: 3.6em;
  @media (min-width: $screen-s){
    font-size: 3.8em;
  }
}

h2{
  font-size: 3.6em;
  margin: 0 0 3vh 0;

  @media (min-width: $screen-s){
    font-size: 5em;
  }
}

h3{
  font-size: 1.6em;
  @media (min-width: $screen-s){
    font-size: 1.8em;
  }
}

h4{
  font-size: 1.2em;
  @media (min-width: $screen-s){
    font-size: 1.7em; 
  }
}

p{
  font-size: 1.2em;
  @media (min-width: $screen-s){
    font-size: 1.7em; 
  }
}
