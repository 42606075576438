/* RESETS */
@import "resets/normalize.scss";
@import "resets/reset.scss";
@import "resets/typo.scss";

/* ASSETS */
@import "assets/colors.scss";
@import "assets/sizes.scss";
@import "assets/typo.scss";
@import "assets/utilities.scss";

/* PLIGINS */
@import "plugins/owl/owl.carousel.scss";
@import "plugins/owl/owl.theme.default.scss";

/* PARTIALS */
@import "partials/sections.scss";
@import "partials/nav.scss";
@import "partials/popup.scss";