html, body{
	height: 100%;
	position: relative;
}

header{
	// background: rgba(255,255,255,.2);
	display: flex;
	justify-content: center;
	padding: 15px;
	position:absolute;
	top: 0;
	width: 100vw;
	z-index: 1000;

	h1{
		color: #000;
		font-size: 1.2em;
		// text-shadow: 1px 1px 0px rgba(255,255,255,0.5), -2px -2px 0px rgba(0,0,0,.2);
	}

	.icn-heart{
		height: 1.5em;
		width: 1.5em;
		fill: url(#heartGradient);
		fill:#000;
		transition: transform .2s;

		&.animate{
			animation: .9s 3 beatHeart;
		}
	}
}

section.section-main{
	align-items: center;
	color: $black;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-height: 100vh;
	max-height: calc(var(--vh, 1vh) * 100);
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
	padding: 4vh 20px 100px 20px;
	padding: calc(var(--vh, 1vh) * 4) 20px 100px 20px;
	position: relative;
	transition: max-height .3s, min-height .3s;

	@media (min-width: $screen-s){
	}

	&.inverted{
		color: $white;
	}

	.title{
		flex: none;

	}

	.content{
		align-items: center;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: flex-start;
		// padding-bottom: 10px;

	  @media (min-width: $screen-s){
	    max-height: 70vh;
	  }

		&>div{
			&:last-child{
				padding: 0;
			}
		}

		.line{
			flex: 1 0 auto;
			
			&:nth-of-type(1){
				margin-top: 0;
			}
		}
	}
}

section.section-main#home{
	// background: $col-home;
	background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #EEEEEE 100%), $col-home;
	padding: 60px 20px 140px 20px;
	// padding: calc(var(--vh, 1vh) * 4) 20px 140px 20px;

	.content{
		display: flex;
		flex-direction: column;
		justify-content: center;
		// margin-bottom: 3vh;
		// margin-bottom:  calc(var(--vh, 1vh) * 3);

		p{
			font-size: 3em;
		}
	}
}

section#about{
	background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 51.56%, rgba(0, 0, 0, 0.04) 100%), $col-about;

	.content{
		&>div{
			padding: 0;
		}
		#profileimage{
			border: $line-width solid $white;
			border-radius: 50%;
			flex-grow: 0;
			height: 30vw;
			max-height: 250px;
			max-width: 250px;
			width: 30vw;
			@media (min-width: $screen-s){
				border: $line-width-s solid $white;
			}
		}
	}	
}

section#services{
	background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 51.56%, rgba(0, 0, 0, 0.04) 100%), $col-services;
	.content{
		display: flex;
		justify-content: space-evenly;

		div{
			flex-grow: 0;
			
			.icon{
				fill: $black;
				height: 50px;
				height: calc(var(--vh, 1vh) * 7);
				width: 50px;
				width: calc(var(--vh, 1vh) * 7);
			}	
		}
		
	}
}

section#work{
	background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 51.56%, rgba(0, 0, 0, 0.04) 98.96%), $col-work;
	padding-left: 0;
	padding-right: 0;

	& #workslider{
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
		// max-width: 1024px;
		

		& .owl-stage-outer{
			display: flex;
			flex: 1;
			max-height: 500px;
		}

		& .owl-stage, & .owl-item{
			display: flex;
			flex: 1;
		}

		& .owl-stage{
			align-items: stretch;
		}

		& .owl-item{
			
		}

		& .workslide{
			align-items: center;
			border: none;
			border-radius: $border-radius;
			background-position: center;
			background-size: cover;
			box-shadow:inset 0 0 0 0px rgba(255,255,255,.5), inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
			display: flex;
			flex: 1 1 auto;
			flex-direction: row;
			justify-content: center;
			margin: 0 20px;
			padding: 20px;
			position:relative;

			&.moreslide{
				border: 1px solid rgba(255,255,255,.2);
				box-shadow: none;
			}

			& .slidecontent{
				section{
					margin-top: 15px;
				}
			}
		}
	}

}

section#contact{
	background: $col-contact;
	padding: 10vh 20px 140px 20px;
	padding: calc(var(--vh, 1vh) * 10) 20px 140px 20px;

	section.content{
		display: flex;
		flex:1;
		flex-direction: column;
		justify-content: center;

		p{
			margin-bottom: 20px;
		}

		div.uxlove{
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: center;
			// margin-left: -8px;
			margin-top: 20px;

			h1{
				// font-size: 3em;	
			}

			.icn-heart{
				cursor: pointer;
				height: 4em;
				margin-top: 3px;
				margin-left: 3px;
				width: 4em;
				fill: white;
				fill: url(#heartGradient);
				fill: rgb(216,0,91);
				transition: transform .2s;

				&:hover{
					transform: scale(1.1);
				}
				
				&.animate{
					animation: .9s 3 beatHeart;
				}
			}	
		}

		p.sitenotice{
			margin-top: 50px;
			& a{
				opacity: .3;
				font-size: .7em;
			}
		}
	}
}

.line{
	background-color: $white;
	margin: 10px auto;
	position: relative;
	width: $line-width;

	@media (min-width: $screen-s){
		margin: 15px auto;
		width: $line-width-s;
	}

	&.bubble:before, &.bubble:after{
		background-color: $white;
		border-radius: 50%;
		display: block;
		height: $line-width*2;
		left: -50%;
		position: absolute;
		width: $line-width*2;

		@media (min-width: $screen-s){
			height: $line-width-s*2;
			width: $line-width-s*2;
		}
	}

	&.bubble-top:before{
		content: '';
		top: -$line-width/2;
		@media (min-width: $screen-s){
			top: -$line-width-s/2;
		}
	}

	&.bubble-bottom:after{
		bottom: -$line-width/2;
		content: '';
		@media (min-width: $screen-s){
			bottom: -$line-width-s/2;
		}
	}
}

@keyframes beatHeart {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.overlay {
	align-items: center;
	justify-content: center;
	display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 10000;
}
.overlay:target {
  visibility: visible;
  opacity: 1;

  & .popup{
  	display: block;
  }
}

.popup {
  overflow: scroll;
  padding: 20px 20px 50px 20px;
  background: #fff;
  border-radius: 5px;
  width: 50%;
  // height: 50%;
  position: relative;
  transition: all 5s ease-in-out;

  & h1{
  	font-size: 2em;
  }

  & h2{
  	font-size: 1.5em;
  }
}

.popup .close {
  position: absolute;
  top: 10px;
  right: 20px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06D85F;
}
.popup .content {
	margin-top: 50px;
  overflow: auto;
}

@media screen and (max-width: 700px){
  .box{
    width: 70%;
  }
  .popup{
    width: 70%;
  }
}
